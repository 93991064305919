import React from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd'
import { createTransitionProductServices } from './../../Redux/Services/Baseline'
import './../../Styles/Components/Baseline/ModalTransitionProduct.css'
import { storeDataBaseline } from '../../Redux/Reducers/Baseline'

const ModalTransitionProduct = ({
    openModal=false,
    setOpenModal=()=>{},
    dataProductTransition,
    setDataProductTransition,
    rex_data_baseline,
}) => {

    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const createTransitionProduct = useMutation({
        mutationFn: (values) => createTransitionProductServices(dispatch, values),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['baseline'] })
        }
    })

    const onFinishForm = async (values) => {
        const dateTransitionMonth = values.dateTransition.format('MM')
        let data_baseline = rex_data_baseline
        const data_filtrada = rex_data_baseline.filter(bas => bas.masterdataproducts.mdpmaterialnumber == dataProductTransition.mdpmaterialnumber)

        const date_month = [
            {month: '01', monthText: 'enero'},
            {month: '02', monthText: 'febrero'},
            {month: '03', monthText: 'marzo'},
            {month: '04', monthText: 'abril'},
            {month: '05', monthText: 'mayo'},
            {month: '06', monthText: 'junio'},
            {month: '07', monthText: 'julio'},
            {month: '08', monthText: 'agosto'},
            {month: '09', monthText: 'septiembre'},
            {month: '10', monthText: 'octubre'},
            {month: '11', monthText: 'noviembre'},
            {month: '12', monthText: 'diciembre'},
        ]

        let state_month = false
        const result_datemonth = date_month.filter(datm => {
            if(datm.month == dateTransitionMonth){
                state_month = true
            }
            
            if(state_month){
                return true
            }
            return false
        })

        const get_data_new = data_filtrada.map(obt => {
            const masterdataproducts = {
                ...obt.masterdataproducts,
                mdpid: null,
                mdpmaterialnumber: values.codeTransition,
                mdpdescripcion: values.descriptionTransition,
            }

            const obtener_prices = obt.prices.map(pri => {
                const find_date = result_datemonth.find(datemonth => datemonth.month == pri.fecfecha.fecmonth)
                if(!find_date){
                    return {
                        ...pri,
                        pricajas: 0,
                        priid: null,
                        prinetlc: 0,
                        prinetprice: 0,
                        prinivlc: 0,
                        prinivprice: 0,
                        privolumen: 0,
                    }
                }

                return { ...pri, priid: null }
            })

            return { ...obt, bslid: null, masterdataproducts: masterdataproducts, prices: obtener_prices }
        })
        
        const data_juntada = data_baseline.concat(get_data_new)

        let data_final = data_juntada.map(dt => {
            if(dt.masterdataproducts.mdpmaterialnumber == dataProductTransition.mdpmaterialnumber){
                const prices_update = dt.prices.map(pri => {
                    const find_date = result_datemonth.find(datemonth => datemonth.month == pri.fecfecha.fecmonth)

                    if(find_date){
                        return {
                            ...pri,
                            pricajas: 0,
                            prinetlc: 0,
                            prinetprice: 0,
                            prinivlc: 0,
                            prinivprice: 0,
                            privolumen: 0,
                        }
                    }

                    return { ...pri }
                })

                return { ...dt, prices: prices_update }
            }

            return { ...dt }
        })

        data_final.map((dt, index) => {
            data_final[index]['key'] = index + 1
        })

        dispatch(storeDataBaseline(data_final))
        setOpenModal(false)
    }

    return (
        
        <Modal
            title={<div className='ModalTransitionProduct__Title'>Nuevo producto de transición</div>} 
            open={openModal}
            onCancel={() => {
                setDataProductTransition({})
                setOpenModal(false)
            }}
            footer={null}
            width={550}
        >
            <Form
                name="transitionProduct"
                onFinish={onFinishForm}
            >
                <Row gutter={[12]}>
                    <Col span={12}>
                        <Form.Item
                            label='Código (SKU):'
                            name='codeTransition'
                            rules={[
                                {
                                  required: true,
                                  message: 'El codigo es requerido',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Inicio:'
                            name='dateTransition'
                            rules={[
                                {
                                  required: true,
                                  message: 'La fecha es requerida',
                                },
                            ]}
                        >
                            <DatePicker
                                picker="month" 
                                placeholder='Seleccionar fecha'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label='Descripcion (SKU):'
                            name='descriptionTransition'
                            rules={[
                                {
                                  required: true,
                                  message: 'El codigo es requerido',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='ModalTransitionProduct__Actions'>
                        <Button 
                            onClick={() => setOpenModal(false)}
                            danger
                        >
                            Cancelar
                        </Button>
                        <Button 
                            htmlType="submit"
                            type='primary'
                        >Guardar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ModalTransitionProduct