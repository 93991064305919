import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, Navigate, Link, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
import { Layout, Menu } from 'antd'
import socket from './../socket'
import Login from './Login/Login'
import Deck from './Data/Deck'
import FileUpload from './FileUpload/FileUpload'
import DownloadData from './DownloadData/DownloadData'
import Baseline from './Data/Baseline'
import AdminDeck from './Administrator/AdminDeck/AdminDeck'
import DownloadFiles from './Administrator/DownloadFiles/DownloadFiles'
import TypeUsers from './Administrator/TypeUsers/TypeUsers'
import Users from './Administrator/Users/Users'
import DeckListado from './DeckListado/DeckListado'
import CreateUpdateUsers from './Administrator/Users/CreateUpdateUsers'
import Audits from './Administrator/Audits/Audits'
import CreateUpdateTypeUsers from './Administrator/TypeUsers/CreateUpdateTypeUsers'
import UploadData from "./UploadData/UploadData";
import ClientsProductsPending from './ClientsProductsPending/ClientsProductsPending'
import EmailControl from './Emails/EmailControl'
import ReplicarSellins from './ReplicarSellins/ReplicarSellins'
import Logout from '../Components/Header/Logout'
import { getStatusDashboardServices, getUpdateStatusServices } from '../Redux/Services/Baseline'
import { GetDataCountriesServices, GetDataPermissionsServices } from '../Redux/Services/Utilities'
import { useQuery, useMutation } from '@tanstack/react-query'
import { LogOutReducer, ValidateUserServices } from '../Redux/Services/Header'
import { funPermisosObtenidosBoolean } from '../Functions/funPermiso'
import '../Styles/Routes/RoutesApp.css'

const RoutesApp = () => {
  const { Header, Content, Footer } = Layout;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { rex_data_perms_user } = useSelector((state) => state.user);

  const { rex_info_status_dashboard } = useSelector((state) => state.baseline);

  const current_year = format(new Date(), "yyyy");

	const items = [
		{
			key: '/deck', 
			label: <Link to='/deck'>Deck</Link>, 
			slug: 'menu.deck'
		},
		{
			key: '/baseline', 
			label: <Link to='/baseline'>Baseline</Link>, 
			slug: 'menu.baseline'
		},
		{
			key: '/file-upload', 
			label: <Link to='/file-upload'>File Upload</Link>, 
			slug: 'menu.fileupload'
		},
		{
			key: '/download-data', 
			label: <Link to='/download-data'>Download Data</Link>, 
			slug: 'menu.downloaddata'
		},
		{
			key: '/deck-listado', 
			label: <Link to='/deck-listado'>Listado Deck</Link>, 
			slug: 'menu.deck'
		},
		{
			key: '/clientes-productos-pendientes', 
			label: <Link to='/clientes-productos-pendientes'>Clientes y productos pendientes</Link>, 
			slug: 'menu.listadopendientes'
		},
	]

	const items2 = [
		{
			label: 'Admin',
			key: 'SubMenu',
			children: [
				{
					key: '/administrador/tipos-usuarios', 
					label: <Link to='/administrador/tipos-usuarios'>Tipos Usuarios</Link>, 
					slug: 'menu.administrador.tiposusuarios'
				},
				{
					key: '/administrador/usuarios', 
					label: <Link to='/administrador/usuarios'>Usuarios</Link>, 
					slug: 'menu.administrador.usuarios'
				},
				{
					key: '/administrador/deck', 
					label: <Link to='/administrador/deck'>Link Deck</Link>, 
					slug: 'menu.administrador.deck'
				},
				{
					key: '/administrador/auditorias', 
					label: <Link to='/administrador/auditorias'>Auditorias</Link>, 
					slug: 'menu.administrador.auditorias'
				},
				{
					key: '/administrador/download-files', 
					label: <Link to='/administrador/download-files'>Donwload Files</Link>, 
					slug: 'menu.administrador.downloadfiles'
				},
        {
          key: "/administrador/upload-files",
          label: <Link to="/administrador/upload-files">Uploaded Files</Link>,
          slug: "menu.administrador.uploadfiles",
        },
        {
          key: "/administrador/email-control",
          label: <Link to="/administrador/email-control">Control Emails</Link>,
          slug: "menu.administrador.emailcontrol",
        },
			]
		},
	]

	const filter_visitante = ['menu.listadodeck', 'menu.listadopendientes']

	const validateUser = useQuery({
		queryKey: ['validateUser'],
		queryFn : () => ValidateUserServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

  const permissions = useQuery({
    queryKey: ["permissions"],
    queryFn: () => GetDataPermissionsServices(dispatch),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const countries = useQuery({
    queryKey: ["countries"],
    queryFn: () => GetDataCountriesServices(dispatch),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const getDataStatusServices = useMutation({
    mutationFn: (page) => getUpdateStatusServices({}, false),
  });

  const getStatusClientDashboardServices = useMutation({
    mutationFn: (page) => getStatusDashboardServices(dispatch),
  });

  const getData = () => {
    if (
      rex_info_status_dashboard.code == "InitialStatus" ||
      rex_info_status_dashboard.code == "OnExecution"
    ) {
      getDataStatusServices.mutate();
    }
  };

  const getDataClient = () => {
    getStatusClientDashboardServices.mutate();
  };

  const onIdle = async () => {
    // const response = await LogOutReducer(dispatch)
    // if(response){
    // 	navigate('/login')
    // }
  };

  const onActive = () => {};

  let timeout = 600000;

  useIdleTimer({
    onIdle,
    onActive,
    timeout,
    throttle: 500,
  });

  // useEffect(() => {

  // 	getData()
  // 	const interval = setInterval(() => {
  // 		getData()
  // 	}, 60000)

  // 	return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    socket.on("get-status-dashboard", (data) => {
      getDataClient();
    });
  }, []);

  const validateToken = () => {
    return !localStorage.getItem("usutoken") ? false : true;
  };

  return (
    <>
      {pathname == "/login" && validateToken() == false ? (
        <Routes>
          <Route exact path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Header
            className="container-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Menu
              className="header-menu"
              mode="horizontal"
              items={
                validateToken()
                  ? items.filter((item) =>
                      funPermisosObtenidosBoolean(
                        rex_data_perms_user,
                        item.slug
                      )
                    )
                  : items.filter((item) => {
                      const find_fv = filter_visitante.find(fv => fv.toLowerCase().includes(item.slug.toLowerCase()))
                      if(find_fv){
                          return false
                      }
                      return true
                  })
              }
              onClick={(e) => navigate(e.key)}
            />
            <div style={{ display: "flex", columnGap: "5px" }}>
              {validateToken() ? (
                <>
                  <Menu
                    className="header-menu"
                    mode="horizontal"
                    items={items2
                      .map((item) => {
                        //Render the items with the right permissions
                        const find_slug = item.children.filter((chil) =>
                          funPermisosObtenidosBoolean(
                            rex_data_perms_user,
                            chil.slug
                          )
                        );

                        // console.log(rex_data_perms_user)

                        return { ...item, children: find_slug };
                      })
                      .filter((item) => item.children.length > 0)}
                    onClick={(e) => navigate(e.key)}
                  />
                  <Logout />
                </>
              ) : (
                <Link to="/login">
                  <span style={{ color: "#000" }}>Login</span>
                </Link>
              )}
            </div>
          </Header>
          <Content
            className="container-content"
            style={{
              padding: "0 48px 20px 48px",
              minHeight: "calc(100vh - 88px)",
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate replace to="/deck" />} />
              {/* <Route path="/login" element={<Navigate replace to="/deck" />} /> */}

              <Route exact path="/file-upload" element={<FileUpload />} />
              <Route exact path="/download-data" element={<DownloadData />} />
              <Route exact path="/baseline" element={<Baseline />} />
              <Route exact path="/deck" element={<Deck />} />
              <Route exact path="/deck-listado" element={<DeckListado />} />
              <Route exact path="/clientes-productos-pendientes" element={<ClientsProductsPending />}/>

              {/* Administrador */}
              <Route exact path="/administrador/usuarios" element={<Users />} />
              <Route
                exact
                path="/administrador/crear-usuarios"
                element={<CreateUpdateUsers />}
              />
              <Route
                exact
                path="/administrador/editar-usuarios/:id"
                element={<CreateUpdateUsers />}
              />
              <Route
                exact
                path="/administrador/tipos-usuarios"
                element={<TypeUsers />}
              />
              <Route
                exact
                path="/administrador/crear-tipos-usuarios"
                element={<CreateUpdateTypeUsers />}
              />
              <Route
                exact
                path="/administrador/editar-tipos-usuarios/:id"
                element={<CreateUpdateTypeUsers />}
              />
              <Route exact path="/administrador/deck" element={<AdminDeck />} />
              <Route
                exact
                path="/administrador/auditorias"
                element={<Audits />}
              />
              <Route
                exact
                path="/administrador/download-files"
                element={<DownloadFiles />}
              />
              <Route
                exact
                path="/administrador/upload-files"
                element={<UploadData />}
              />
              <Route
                exact
                path="/administrador/email-control"
                element={<EmailControl />}
              />
            </Routes>
          </Content>
          <Footer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "48px",
              backgroundColor: "#ffffff",
              padding: 0,
            }}
          >
            Todos los derechos reservados a @Grow Analytics {current_year}
          </Footer>
        </Layout>
      )}
    </>
  );
};

export default RoutesApp;
