import config from "../config";

export const DeleteEmail = async (email, templateName, recipientType) => {
  try {
    const deleteEmail = await fetch(
      config.api_url +
        `emails/delete-email?email=${email}&templateName=${templateName}&recipientType=${recipientType}`,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          usutoken: localStorage.getItem("usutoken"),
        },
      }
    );

    if (!deleteEmail.ok) {
      throw new Error("Error al eliminar email");
    }

    const response = await deleteEmail.json();

    return response;
  } catch (error) {
    console.error("Error borrando email:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
};
