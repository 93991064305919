import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storeSelectedCountry } from "../../Redux/Reducers/Utilities";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { Col, Row, Table, Input, DatePicker, Select } from "antd";
import {
  GetUploadedFilesInfo,
  GetUploadedFilesInfoWithFile,
} from "../../Redux/Services/Administrator/UploadFiles";
import { FileTypes } from "../../Redux/Services/Administrator/FileTypes";
import { Modal } from "antd";
import {
  SoundOutlined,
  SearchOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const UploadData = () => {
  //FileTypes
  const [fileTypes, setFileTypes] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [runUseQuery, setRunUseQuery] = useState(false);

  //Get array of file types to show in the select
  useEffect(() => {
    const fetchFileTypes = async () => {
      try {
        const response = await FileTypes();
        const fileTypeNames = response.map((fileType, index) => {
          return { value: index + 1, label: fileType.tcanombre };
        });

        // setSelectedFileType(fileTypeNames[0]);
        setFileTypes(fileTypeNames);
      } catch (error) {
        console.error("Error fetching file types:", error);
      }
    };

    fetchFileTypes();
  }, []);

  //Set initial current page to one
  const [currentPage, setCurrentPage] = useState(1);
  //Max items per page
  const pageSize = 15;

  //Data source for table
  const [dataSource, setDataSource] = useState([]);

  //Control visibility of notifications modal
  const [notificationsAreVisible, setNotificationsAreVisible] = useState(false);

  //Keep track of notifications
  const [notificationList, setNotificationList] = useState([]);

  //Get information of uploaded files
  const { data: uploadedFiles, isLoading } = useQuery({
    queryKey: ["uploadedFiles", runUseQuery],
    queryFn: () => GetUploadedFilesInfo(setDataSource),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  //onClick Functions
  const showNotification = (record) => {
    setNotificationsAreVisible(true);

    let { carnotificaciones: notification } = record;

    //Convert string into array of data
    if (typeof notification === "string") {
      try {
        notification = JSON.parse(notification);
      } catch (error) {
        console.error("Error al formatear notificacion:", error);
        notification = null;
      }
    }

    // console.log(notification);

    if (notification?.length) {
      notification = notification.map((notificationItem) => {
        const notificationTitle = notificationItem.columna;
        const notificationMessage = notificationItem.notificaciones[0].msg;
        const notificationRows = notificationItem.notificaciones[0].rows;

        return { notificationTitle, notificationMessage, notificationRows };
      });

      setNotificationList(notification);
    }
  };

  //ant desgin table columns
  const columns = [
    {
      title: "N°",
      dataIndex: "key",
      showSorterTooltip: false,
      render: (_, __, index) => (
        <div title={index + 1 + (currentPage - 1) * pageSize}>
          {index + 1 + (currentPage - 1) * pageSize}
        </div>
      ),
      width: "50px",
      align: "center",
    },
    {
      title: "NOMBRE USUARIO",
      dataIndex: "fullName",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Buscar Nombre"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
                setSelectedKeys([]);
              }}
              onBlur={() => {
                confirm();
              }}
              suffix={<SearchOutlined />}
              style={{
                width: 200,
                borderRadius: 4,
              }}
              allowClear
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        return record.usuusuarios
          ? record.usuusuarios.fullName
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      },
      sorter: (a, b) => {
        if (a.usuusuarios && b.usuusuarios) {
          return a.usuusuarios.fullName.localeCompare(b.usuusuarios.fullName);
        }
        return 0;
      },
      render: (text, record) =>
        record.usuusuarios ? record.usuusuarios.fullName : "-",
    },
    {
      title: "CORRREO USUARIO",
      dataIndex: "usuusuarios.usuusuario",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Buscar Correo"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
                setSelectedKeys([]);
              }}
              onBlur={() => {
                confirm();
              }}
              suffix={<SearchOutlined />}
              style={{
                width: 200,
                borderRadius: 4,
              }}
              allowClear
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        return record.usuusuarios
          ? record.usuusuarios.usuusuario
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      },
      sorter: (a, b) => {
        if (a.usuusuarios && b.usuusuarios) {
          return a.usuusuarios.usuusuario.localeCompare(
            b.usuusuarios.usuusuario
          );
        }
        return 0;
      },
      render: (text, record) =>
        record.usuusuarios ? record.usuusuarios.usuusuario : "-",
    },
    {
      title: "NOMBRE ARCHIVO",
      dataIndex: "carnombre",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Buscar nombre archivo"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
                setSelectedKeys([]);
              }}
              onBlur={() => {
                confirm();
              }}
              suffix={<SearchOutlined />}
              style={{
                width: 200,
                borderRadius: 4,
              }}
              allowClear
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        return record.carnombre
          ? record.carnombre.toLowerCase().includes(value.toLowerCase())
          : false;
      },
      sorter: (a, b) => {
        if (a.carnombre && b.carnombre) {
          return a.carnombre.localeCompare(b.carnombre);
        }
        return 0;
      },
      render: (text, record) =>
        record.carnombre ? (
          <div title={record.carnombre}>
            <a href={record.carurl}>{record.carnombre}</a>
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "TIPO ARCHIVO",
      dataIndex: "tipoArchivo",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Buscar tipo archivo"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
                setSelectedKeys([]);
              }}
              onBlur={() => {
                confirm();
              }}
              suffix={<SearchOutlined />}
              style={{
                width: 200,
                borderRadius: 4,
              }}
              allowClear
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        console.log(record);
        return record.tcatiposcargasarchivos
          ? record.tcatiposcargasarchivos.tcanombre
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      },
      sorter: (a, b) => {
        if (a.tcatiposcargasarchivos && b.tcatiposcargasarchivos) {
          return a.tcatiposcargasarchivos.tcanombre.localeCompare(
            b.tcatiposcargasarchivos.tcanombre
          );
        }
        return 0;
      },
      render: (text, record) =>
        record.tcatiposcargasarchivos
          ? record.tcatiposcargasarchivos.tcanombre
          : "-",
    },
    {
      title: "NOTIFICACIONES",
      dataIndex: "carnotificaciones",
      render: (text, record) => (
        <SoundOutlined
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            showNotification(record);
          }}
        />
      ),
    },
    {
      title: "FECHA SUBIDA",
      dataIndex: "date",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <div style={{ padding: 8 }}>
            <DatePicker
              placeholder="Buscar por fecha"
              onChange={(date) => {
                setSelectedKeys(date ? [date.format("YYYY-MM-DD")] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              suffix={<SearchOutlined />}
              style={{
                width: 200,
                borderRadius: 4,
              }}
              allowClear
            />
          </div>
        );
      },
      filterIcon: () => <CalendarOutlined />,
      onFilter: (value, record) => {
        return record.created_at
          ? moment(record.created_at).isSame(value, "day")
          : false;
      },
      render: (text, record) =>
        record.created_at
          ? moment(record.created_at).format("DD/MM/YYYY hh:mm a")
          : "-",
    },
  ];

  return (
    <>
      <Row>
        <Col span={18} style={{ margin: "25px 0 10px 0" }}>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            Control de Subida de Archivos
          </div>
        </Col>
        <Col
          span={6}
          style={{
            margin: "25px 0 10px 0",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Select
            style={{ width: "150px" }}
            onChange={async (e, dt) => {
              //Create current File type
              const currentFileType = {
                value: dt?.value,
                label: dt?.label,
              };

              if (dt?.value) {
                //Call function to filter uploaded files by name and update datasource to update table fields
                await GetUploadedFilesInfoWithFile(
                  setDataSource,
                  currentFileType.label
                );

                //Keep track of the currrent selected type in the select
                setSelectedFileType(currentFileType);
              }
            }}
            options={fileTypes}
            value={selectedFileType ? selectedFileType.label : ""}
            allowClear={true}
            onClear={() => {
              setRunUseQuery(!runUseQuery);
              setSelectedFileType(null);
            }}
          />
        </Col>
        <Col span={24}>
          <Table
            pagination={{
              current: currentPage,
              position: ["topRight", "none"],
              pageSize: pageSize,
              total: uploadedFiles?.pagination?.totalItems,
              size: "small",
              onChange: (page) => {
                setCurrentPage(page);
              },
              showTotal: (total) => `Total: ${total} registros`,
              showSizeChanger: false,
            }}
            scroll={{
              x: "1000px",
              y: "370px",
            }}
            dataSource={dataSource}
            loading={isLoading}
            columns={columns}
            className="Table-Audits"
            size="small"
          />
          <Modal
            title="Notificaciones"
            open={notificationsAreVisible}
            onCancel={() => {
              setNotificationsAreVisible(false);
              setNotificationList([]);
            }}
            footer={null}
          >
            {notificationList?.length > 0 ? (
              notificationList.map((notification, index) => (
                <div key={index} style={{ marginBottom: "15px" }}>
                  <p
                    style={{
                      color: "red",
                      marginBottom: "8px",
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <span style={{ flexShrink: 0 }}>{`${index + 1})`}</span>
                    <span style={{ wordBreak: "break-word" }}>
                      {`Se encontraron las siguientes observaciones en la columna `}
                      <strong>{notification.notificationTitle}</strong>
                      {`:`}
                    </span>
                  </p>
                  <p
                    style={{
                      color: "red",
                      paddingLeft: "20px",
                      display: "flex",
                      gap: "8px",
                      margin: 0,
                    }}
                  >
                    <span style={{ flexShrink: 0 }}>-</span>
                    <span
                      style={{
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {`${notification.notificationMessage} en las siguientes lineas: `}
                      <strong>
                        {notification.notificationRows.toString()}
                      </strong>
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <h3 style={{ color: "blue" }}>Archivo cargado exitosamente</h3>
            )}
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UploadData;
