import { createSlice } from "@reduxjs/toolkit"

export const ClientsProductsPending = createSlice({
    name : 'clientsProductsPending',
    initialState : {
        rex_data_clients_pending: [],
        rex_data_products_pending: [],
        rex_selected_type : { value: 'Baseline', label: 'Baseline' }
    },
    reducers : {
        storeDataClientsPending: (state, action) => {
            state.rex_data_clients_pending = action.payload
        },
        storeDataProductsPending: (state, action) => {
            state.rex_data_products_pending = action.payload
        },
        storeSelectedType: (state, action) => {
            state.rex_selected_type = action.payload
        },
    }
})

export default ClientsProductsPending.reducer
export const { storeDataClientsPending, storeDataProductsPending, storeSelectedType } = ClientsProductsPending.actions