import { createSlice } from "@reduxjs/toolkit"

export const DeckListado = createSlice({
    name : 'decklistado',
    initialState : {
        rex_data_deck_listado: [],
        rex_range_months_sellin: {},
        rex_modal_message: { message: '', error: false },
        rex_modal_visible: false,
        rex_selected_dates: {}
    },
    reducers : {
        storeDataDeckListado: (state, action) => {
            state.rex_data_deck_listado = action.payload
        },
        storeRangeMonthsSellin: (state, action) => {
            state.rex_range_months_sellin = action.payload
        },
        storeModalMessage: (state, action) => {
            state.rex_modal_message = action.payload
        },
        storeModalVisible: (state, action) => {
            state.rex_modal_visible = action.payload
        },
        storeSelectedDates: (state, action) => {
            state.rex_selected_dates = action.payload
        }
    }
})

export default DeckListado.reducer
export const { 
    storeDataDeckListado, 
    storeRangeMonthsSellin, 
    storeReplicateSellinLoading,
    storeModalMessage,
    storeModalVisible,
    storeSelectedDates
} = DeckListado.actions