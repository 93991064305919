import config from "../../../config";

export const GetUploadedFilesInfo = async (setDataSource) => {
  const uploadedFilesData = await fetch(
    config.api_url + `file-upload/uploaded-info`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        usutoken: localStorage.getItem("usutoken"),
      },
    }
  );

  const response = await uploadedFilesData.json();

  setDataSource(response.data);

  // console.log({ ...response, data: response.data });
  return { ...response, data: response.data };
};

export const GetUploadedFilesInfoWithFile = async (setDataSource, fileType) => {
  const uploadedFilesData = await fetch(
    config.api_url + `file-upload/uploaded-info/fileType?file=${fileType}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        usutoken: localStorage.getItem("usutoken"),
      },
    }
  );

  const response = await uploadedFilesData.json();

  setDataSource(response.data);
};
