export const filterEmailsByTemplate = (emailData, templateName) => {
  //filter by template name
  const filterBaselineResponse = emailData.filter(
    (email) => email.eutUploadTemplate === templateName
  );
  //filter by euttype
  const toEmails = filterBaselineResponse
    .filter((email) => email.euttype === "to")
    .map((email) => email.eutemail);

  const ccEmails = filterBaselineResponse
    .filter((email) => email.euttype === "cc")
    .map((email) => email.eutemail);

  const bccEmails = filterBaselineResponse
    .filter((email) => email.euttype === "bcc")
    .map((email) => email.eutemail);

  const filteredEmails = {
    toEmails,
    ccEmails,
    bccEmails,
  };

  return filteredEmails;
};
