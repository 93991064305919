import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Col, Row, Table, Select, Input } from 'antd'
import { GetDataDeckListadoServices } from "../../Redux/Services/ClientsProductsPending"
import { storeSelectedType } from "../../Redux/Reducers/ClientsProductsPending"
import {
    FilterOutlined,
} from '@ant-design/icons'

const ClientsProductsPending = () => {
    const [currentPageClients, setCurrentPageClients] = useState(1)
    const [currentPageProducts, setCurrentPageProducts] = useState(1)
    const pageSize = 15
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

	const { 
        rex_data_clients_pending,
        rex_data_products_pending,
        rex_selected_type,
    } = useSelector(state => state.clientsProductsPending)

    const getDataClientsProductsPenging = useQuery({
        queryKey: ['getDataClientsProductsPending'],
        queryFn : () => GetDataDeckListadoServices(dispatch, rex_selected_type),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const getColumnSearchProps = (dataIndex, texto) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{padding: 8,}}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${texto}`}
                    value={selectedKeys[0]}
                    onChange={async (e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{display: 'block'}}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color: '#fff'}} />),
        onFilter: (value, record) => {
            let valueData
            if(dataIndex.length == 3){
                if(record[dataIndex[0]]){
                    if(record[dataIndex[0]][dataIndex[1]]){
                        valueData = record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
                    }
                }
            }else if(dataIndex.length == 2){
                if(record[dataIndex[0]]){
                    valueData = record[dataIndex[0]][dataIndex[1]]
                }
            }else if(dataIndex.length == 1){
                valueData = record[dataIndex[0]]
            }

            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columnsClients = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, __, index) => <div title={index + 1 + (currentPageClients - 1) * pageSize}>{index + 1 + (currentPageClients - 1) * pageSize}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Codigo Forecast Group',
            dataIndex: 'fcst_group',
            ...getColumnSearchProps(['fcst_group'], 'Codigo Forecast Group'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record?.fcst_group}>{record?.fcst_group}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Pais',
            dataIndex: 'pai_nombre',
            showSorterTooltip: false,
            render: (_, record) => <div title={record.pai_nombre ? record.pai_nombre : ''}>{record.pai_nombre ? record.pai_nombre : ''}</div>,
            width: '100px',
            align:'center'
        },
    ]

    const columnsProducts = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, __, index) => <div title={index + 1 + (currentPageProducts - 1) * pageSize}>{index + 1 + (currentPageProducts - 1) * pageSize}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Cod Sku',
            dataIndex: 'material_number',
            ...getColumnSearchProps(['material_number'], 'Cod Sku'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record?.material_number}>{record?.material_number}</div>,
            width: '100px',
            align:'center'
        },
    ]

    return (
        <>
        <Row>
            <Col span={18} style={{ margin: '25px 0 10px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Listado de clientes y productos pendientes</div>
			</Col>
            <Col span={6} style={{ margin: '25px 0 10px 0', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Select
                    style={{ width: '120px'}}
                    onChange={async (e, dt) => {
                        const selected_country = {
                            value: dt.value,
                            label: dt.label,
                        }
                        await dispatch(storeSelectedType(selected_country))
                        queryClient.invalidateQueries({ queryKey: ['getDataClientsProductsPending'] })
                    }}
                    options={[
                        {value: 'Baseline', label: 'Baseline'},
                        {value: 'Forecast', label: 'Forecast'},
                        {value: 'Sell In', label: 'Sell In'},
                        {value: 'Sell Out', label: 'Sell Out'},
                    ]}
                    value={rex_selected_type.value}
                />
            </Col>
        </Row>
        <Row gutter={[40, 0]}>
            <Col span={12}>
                <Table 
                    pagination={{
                        position: ["none", "bottomRight"],
                        pageSize: pageSize,
                        size: 'small',
                        onChange: (page) => setCurrentPageClients(page),
                        showTotal: (total) => `Total: ${total} registros`
                    }}
                    scroll={{
                        x: 'auto',
                        y: '370px',
                    }}
                    loading={getDataClientsProductsPenging.isPending || getDataClientsProductsPenging.isFetching}
                    columns={columnsClients}
                    dataSource={rex_data_clients_pending} 
                    className="Table-Audits Clients-Products-Pending"
                    size='small'
                />
            </Col>
            <Col span={12}>
                <Table 
                    pagination={{
                        position: ["none", "bottomRight"],
                        pageSize: pageSize,
                        size: 'small',
                        onChange: (page) => setCurrentPageProducts(page),
                        showTotal: (total) => `Total: ${total} registros`
                    }}
                    scroll={{
                        x: 'auto',
                        y: '370px',
                    }}
                    loading={getDataClientsProductsPenging.isPending || getDataClientsProductsPenging.isFetching}
                    columns={columnsProducts}
                    dataSource={rex_data_products_pending} 
                    className="Table-Audits Clients-Products-Pending"
                    size='small'
                />
            </Col>
        </Row>
        </>
    )
}

export default ClientsProductsPending