import React, { useContext, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Col, Row, Table, Button, Select, Checkbox, Form, DatePicker, Modal, Typography, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ChangeOrderDeckListadoServices, ChangeStatusDeckListadoServices, GetDataDeckListadoServices, ReplicateSellinListadoDeck, DeleteDeckListadoServices } from "../../Redux/Services/DeckListado"
import { storeDataDeckListado, storeModalMessage, storeModalVisible, storeSelectedDates } from "../../Redux/Reducers/DeckListado"
import { storeSelectedCountry } from "../../Redux/Reducers/Utilities"
import { refreshDashboardServices } from "../../Redux/Services/Baseline"
import moment from "moment"
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    HolderOutlined,
} from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import '../../Styles/Routes/DeckListado.css'

const RowContext = React.createContext({})

const ButtonOrden = () => {
    const { setActivatorNodeRef, listeners, styleButton } = useContext(RowContext)
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={styleButton}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    )
}

const RowTable = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        isDragging,
    } = useSortable({id: props['data-row-key']})

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    return (
        <RowContext.Provider value={{setActivatorNodeRef, listeners, styleButton}}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    )
}

const DeckListado = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const [dataSource, setDataSource] = useState([]);
    //Selected dates for each row
    const [filterMonth, setFilterMonth] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

      const { RangePicker } = DatePicker;
      const { Paragraph } = Typography;


	const { 
        rex_data_deck_listado, 
        rex_range_months_sellin, 
        rex_modal_message,
        rex_modal_visible,
        rex_selected_dates 
    } = useSelector(state => state.deckListado)

	const { 
        rex_validate_countries,
        rex_data_countries, 
        rex_selected_country,
    } = useSelector(state => state.utilities)

    const baselineForecast = useQuery({
        queryKey: ['deckListadoervices'],
        queryFn : () => GetDataDeckListadoServices(dispatch, rex_selected_country, rex_data_deck_listado),
        retry: 1,
        refetchOnWindowFocus : false,
        enabled: !!rex_validate_countries
    })

    const changeBaselineForecast = useMutation({
        mutationFn: (state) => ChangeStatusDeckListadoServices(state, dispatch),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['deckListadoervices'] })
        }
    })

    useEffect(() => {
        setDataSource(rex_data_deck_listado);
    }, [rex_data_deck_listado]);



    const changeOrderBaselineForecast = useMutation({
        mutationFn: (data_deck) => ChangeOrderDeckListadoServices(data_deck, dispatch),
    })

    const columns = [
        {
            key: 'sort',
            align: 'center',
            width: 20,
            render: (_, record) => {
                if (record.sellin_replica_processing || record.delete_decks_processing) {
                    return <Spin indicator={<LoadingOutlined spin />} 
                            spinning={true} />
                } else {
                    return <ButtonOrden />
                }
            },
        },
        {
            title: 'Item',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, record) => {
                if (record.sellin_replica_processing || record.delete_decks_processing) {
                    const replicatingSellins = record.sellin_replica_processing;

                    if (replicatingSellins) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                                <span style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: '#DAA520',
                                    borderRadius: '50%',
                                    display: 'inline-block'
                                }}/>
                                <div style={{color: '#DAA520'}} title={'Replicando Sell ins..'}>
                                    {'Replicando Sell ins..'}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                                <span style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    display: 'inline-block'
                                }}/>
                                <div style={{color: 'red'}} title={'Eliminando..'}>
                                    {'Eliminando..'}
                                </div>
                            </div>
                        )
                    }
        
                } else if (record.inQueue) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                            <span style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: '#666666',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}/>
                            <div style={{color: '#666666'}} title={'Esperando..'}>
                                {'Esperando..'}
                            </div>
                        </div>
                    )
                } else if (record.completed) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                            <span style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: '#28a745',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}/>
                            <div style={{color: 'green'}} title={'Completado'}>
                                {'Completado'}
                            </div>
                        </div>
                    )
                } else if (record.interrupted) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                            <span style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: '#666666',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}/>
                            <div style={{color: '#666666'}} title={'Completado'}>
                                {'Interrumpido'}
                            </div>
                        </div>
                    )
                } else if(record.withError) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                            <span style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}/>
                            <div style={{color: 'red'}} title={'Completado'}>
                                {'Hubo un error'}
                            </div>
                        </div>
                    )
                } else {
                    return <div title={record?.key}>{record?.key}</div>
                }
            },
            width: '100px',
            align:'center'
        },
        {
            title: 'Nombre',
            dataIndex: 'mdlnombre',
            showSorterTooltip: false,
            render: (_, record) => <div title={record?.mdlnombre}>{record?.mdlnombre}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Tipo',
            dataIndex: 'mdltext',
            showSorterTooltip: false,
            render: (_, record) => <div title={record.mdltext ? record.mdltext : ''}>{record.mdltext ? record.mdltext : ''}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Paises',
            dataIndex: 'paipaises',
            showSorterTooltip: false,
            render: (_, record) => <div title={record.paipaises ? record.paipaises.painombre : ''}>{record.paipaises ? record.paipaises.painombre : ''}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Visualización',
            dataIndex: 'mdlestado',
            showSorterTooltip: false,
            render: (_, record) => <div>
                {
                    record.mdlestado
                    ?   <EyeOutlined 
                            className="Icon-Visible-Baseline-Forecast"
                            onClick={() => {
                                const info = { mdlid: record.mdlid, mdlestado: false }
                                changeBaselineForecast.mutate(info)

                                const updatedDataSource = dataSource.map((item) => {
                                    if (item.key === record.key) {
                                        return { ...item, mdlestado: false };
                                    }

                                    return item;
                                })

                                setDataSource(updatedDataSource);
                            }} 
                        />
                    :   <EyeInvisibleOutlined 
                            className="Icon-Not-Visible-Baseline-Forecast"
                            onClick={() => {
                                const info = { mdlid: record.mdlid, mdlestado: true }
                                changeBaselineForecast.mutate(info)

                                const updatedDataSource = dataSource.map((item) => {
                                    if (item.key === record.key) {
                                        return { ...item, mdlestado: true };
                                    }

                                    return item;
                                })

                                setDataSource(updatedDataSource);
                            }}
                        />
                }
            </div>,
            width: '50px',
            align:'center'
        },
        {
            title: "Replicar",
            dataIndex: "replicar",
            render: ( _, record) => { 
                const isBaselineOrForecast = record.mdltext !== 'Sell out' && record.mdltext !== 'Sell in';

                const isDataProcessing = dataSource.some(item => item.sellin_replica_processing || item.delete_decks_processing);

                return isBaselineOrForecast ? 
                    <Checkbox
                      disabled={record.eliminar || isDataProcessing}
                      checked={record.replicar}
                      onChange={(e) => {
          
                        const updateReduxDeckListado = rex_data_deck_listado.map((item) => {
                            if (item.key === record.key) {
                                return { ...item, replicar: e.target.checked };
                            }
                            return item;
                        });

                        dispatch(storeDataDeckListado(updateReduxDeckListado))
                        setDataSource(updateReduxDeckListado);
          
                      }}
          
                    />
                   : '-';
            },
            width: '50px',
            align:'center'
        },
        {
            title: "Eliminar",
            dataIndex: "eliminar",
            render: ( _, record) => {
                const isBaselineOrForecast = record.mdltext !== 'Sell out' && record.mdltext !== 'Sell in';

                const isDataProcessing = dataSource.some(item => item.sellin_replica_processing || item.delete_decks_processing);

                return isBaselineOrForecast ? (
                    <Checkbox
                      disabled={record.replicar || isDataProcessing}
                      checked={record.eliminar}
                      onChange={(e) => {
          
                        const updatedDataSourceWithChecks = rex_data_deck_listado.map((item) => {
                          if (item.key === record.key) {
                            return { ...item, eliminar: e.target.checked };
                          }
                          return item;
                        });
                        
                        dispatch(storeDataDeckListado(updatedDataSourceWithChecks));
                        setDataSource(updatedDataSourceWithChecks);
                      }}
                    />
                  ) : '-';
            },
            width: '50px',
            align:'center'
          },
          {
            title: "Meses a replicar",
            dataIndex: "meses",
            render: (_, record) => {
                const isBaselineOrForecast = record.mdltext !== 'Sell out' && record.mdltext !== 'Sell in';

                return isBaselineOrForecast ? (
                    <RangePicker
                      disabled={!record.replicar || record.sellin_replica_processing || record.delete_decks_processing || record.inQueue}
                      picker="month"
                      format="MMMM"
                      size="small"
                      value={record.replicar ? rex_selected_dates[record.key] : null}
                      disabledDate={(current) => {
  
                        if(rex_range_months_sellin.startDate === '' || rex_range_months_sellin.endDate === '') { 
                            return true;
                        }

                        // Example: Disable months before January 2025 and after March 2025
                        const startDate = moment(rex_range_months_sellin.startDate, 'YYYY-MM');
                        const endDate = moment(rex_range_months_sellin.endDate, 'YYYY-MM');

                        return current && (current < startDate.startOf('month') || current > endDate.endOf('month'));
                      }}
                      onChange={(dates, dateStrings) => {
      
                        const updatedDataSourceWithMonths = rex_data_deck_listado.map((item) => {
                          if (item.key === record.key) {
                            return { ...item, meses: dateStrings };
                          }
                          return item;
                        });
                        
                        dispatch(storeDataDeckListado(updatedDataSourceWithMonths));
                        setDataSource(updatedDataSourceWithMonths);
                        dispatch(storeSelectedDates({ ...rex_selected_dates, [record.key]: dates }));
                      }}
                    />
                  ) : '-';
            },
            width: '100px',
            align:'center'
          },
          {
            title: "Fecha de subida",
            dataIndex: "fecha",
            render: (_, record) => {    
                return <div title={record?.fecha}>{record?.fecha}</div>
            },
            width: '100px',
            align:'center'
          },
          {
            title: "Usuario que cargó",
            dataIndex: "userUpload",
            render: (_, record) => {    
                const userUploadName = record?.userUpload;
                const userUploadIsNull = userUploadName === null;

                return <div title={userUploadName}>{userUploadIsNull ? '-' : userUploadName}</div>
            },
            width: '50px',
            align:'center'
          },
          {
            title: "Usuario que actualizó",
            dataIndex: "userUpdate",
            render: (_, record) => {    
                const userUpdateName = record?.userUpdate;
                const userUpdateIsNull = userUpdateName === null;

                return <div title={userUpdateName}>{userUpdateIsNull ? '-' : userUpdateName}</div>
            },
            width: '50px',
            align:'center'
          }
    ]

    const onDragEnd = async (event) => {
        const { active, over } = event
        
        if(active.id !== over.id){
            const activeIndex = rex_data_deck_listado.findIndex(i => i.mdlorden === active.id)
            const overIndex = rex_data_deck_listado.findIndex(i => i.mdlorden === over.id)
            const arrayChange = arrayMove(rex_data_deck_listado, activeIndex, overIndex)
            
            await dispatch(storeDataDeckListado(arrayChange))
            changeOrderBaselineForecast.mutate(arrayChange)
        }
    }

    const replicateSellins = async (selectedFilesSpanishMonths, decksToReplicateOnQueue) => {
        let temp = decksToReplicateOnQueue;
        let fileProcessing;
        
        try {
            for (const file of selectedFilesSpanishMonths) {
                // console.log('iniciando replica..', temp.filter((item) => item.inQueue === true));
    
                temp = temp.map((item) => {
                    
                    if(item.mdlid === file.mdlid) {
                        fileProcessing = item;
                        return { ...item, inQueue: false, sellin_replica_processing: true };
                    }
    
                    return item;
                });
    
                dispatch(storeDataDeckListado(temp));
    
                // await new Promise(resolve => setTimeout(resolve, 3000));
    
                await ReplicateSellinListadoDeck(file);
                
                temp = temp.map((item) => {
                    
                    if(item.mdlid === file.mdlid) {
                        return { ...item, inQueue: false, sellin_replica_processing: false, completed: true };
                    }
    
                    return item;
                });
    
                dispatch(storeDataDeckListado(temp));
    
                // console.log('terminando replica..', temp.filter((item) => item.inQueue === true));
                // await new Promise(resolve => setTimeout(resolve, 3000));
    
            };
    
            return temp   

        } catch (error) {
            const itemsLeftInQueue = temp.filter((item) => item.inQueue === true);

            temp = temp.map((item) => {     
                if(item.mdlid === fileProcessing.mdlid) {
                    return { ...item, inQueue: false, sellin_replica_processing: false, withError: true, completed: false };
                }

                if(itemsLeftInQueue.some((itemLeftInQueue) => itemLeftInQueue.mdlid === item.mdlid)) { 
                    return { ...item, inQueue: false, sellin_replica_processing: false, interrupted: true, completed: false };
                }

                return item;
            });

            dispatch(storeDataDeckListado(temp));

            throw new Error('Error replicando sell ins: ' + error.message);
        }
    }

    const deleteDecks = async (decksToDelete, decksToDeleteOnQueue) => {
        let temp = decksToDeleteOnQueue;
        let fileProcessing;

        try {
            for (const file of decksToDelete) {
                // console.log('iniciando delete..', temp.filter((item) => item.inQueue === true));
    
                temp = temp.map((item) => {
                    
                    if(item.mdlid === file.mdlid) {
                        fileProcessing = item;
                        return { ...item, inQueue: false, delete_decks_processing: true };
                    }
    
                    return item;
                });
    
                dispatch(storeDataDeckListado(temp));
    
                // await new Promise(resolve => setTimeout(resolve, 3000));
    
                await DeleteDeckListadoServices(file);
                
                temp = temp.map((item) => {
                    
                    if(item.mdlid === file.mdlid) {
                        return { ...item, inQueue: false, delete_decks_processing: false, completed: true };
                    }
    
                    return item;
                });
    
                dispatch(storeDataDeckListado(temp));
    
                // console.log('terminando delete..', temp.filter((item) => item.inQueue === true));
                // await new Promise(resolve => setTimeout(resolve, 3000));
            };
    
            return temp   
        } catch (error) {
            const itemsLeftInQueue = temp.filter((item) => item.inQueue === true);

            temp = temp.map((item) => {     

                if(item.mdlid === fileProcessing.mdlid) {
                    return { ...item, inQueue: false, delete_decks_processing: false, withError: true, completed: false };
                }

                if(itemsLeftInQueue.some((itemLeftInQueue) => itemLeftInQueue.mdlid === item.mdlid)) { 
                    return { ...item, inQueue: false, delete_decks_processing: false, interrupted: true, completed: false };
                }

                return item;
            });

            console.log('temp', temp)

            dispatch(storeDataDeckListado(temp));

            throw new Error('Error eliminando decks: ' + error.message);
        }
    }

    const handleSubmitButton = async () => {
        try {
            const decksToDelete = rex_data_deck_listado.filter((item) => item.eliminar === true);
            const decksToReplicateSellin = rex_data_deck_listado.filter((item) => item.replicar === true);
            const decksCheckedToDeleteAndReplicate = rex_data_deck_listado.filter(row => row.replicar && row.eliminar);

            //Validar que no se haya seleccionado replicar y eliminar al mismo tiempo
            if (decksCheckedToDeleteAndReplicate.length > 0) {
                throw new Error('No se puede replicar y eliminar el mismo deck');
            }

            const monthsInSpanish = {
                January: 'enero',
                February: 'febrero',
                March: 'marzo',
                April: 'abril',
                May: 'mayo',
                June: 'junio',
                July: 'julio',
                August: 'agosto',
                September: 'septiembre',
                October: 'octubre',
                November: 'noviembre',
                December: 'diciembre'
            };

            //Validar que todos los archivos seleccionados tengan un rango de meses válido
            const invalidFiles = decksToReplicateSellin?.filter(file => !Array.isArray(file.meses) || file.meses.length !== 2);

            if (invalidFiles?.length > 0) {
                throw new Error('Todos los archivos a replicar deben tener un rango de meses válido');
            }

            setIsLoading(true);

            //Replicar Sell ins
            //Si hay archivos seleccionados, replicar sellin
            let decksToReplicateOnQueue = [];
            if(decksToReplicateSellin.length > 0) {
                decksToReplicateOnQueue = rex_data_deck_listado.map((item) => {
                    if (item.replicar) {
                        return { ...item, inQueue: true };
                    } else if (item.eliminar) {
                        return { ...item, inQueue: true };
                    }
                    return item;
                });

                dispatch(storeDataDeckListado(decksToReplicateOnQueue));

                const selectedFilesSpanishMonths = decksToReplicateSellin?.map(file => ({
                    ...file,
                    meses: file.meses.map(month => monthsInSpanish[month])
                }));

                decksToReplicateOnQueue = await replicateSellins(selectedFilesSpanishMonths, decksToReplicateOnQueue);
            }

            //Eliminar decks
            //Si hay archivos seleccionados para eliminar, eliminarlos
            if (decksToDelete.length > 0) { 
                const updatedSource = decksToReplicateOnQueue.length > 0 ? decksToReplicateOnQueue : rex_data_deck_listado;

                const decksToDeleteOnQueue = updatedSource.map((item) => {
                    if (item.eliminar) {
                        return { ...item, inQueue: true };
                    }
                    return item;
                });

                dispatch(storeDataDeckListado(decksToDeleteOnQueue));

                decksToReplicateOnQueue = await deleteDecks(decksToDelete, decksToDeleteOnQueue);
            }

            //Actualizar Dashboard
            const updateDashboardResponse = await refreshDashboardServices(dispatch);

            // Resetear inputs despues de replicar sellin
            const sourceToClean = decksToReplicateOnQueue.length > 0 ? decksToReplicateOnQueue : rex_data_deck_listado;
            const cleanState = sourceToClean.map(item => ({ ...item, replicar: false, eliminar: false, meses: [] }));
            dispatch(storeDataDeckListado(cleanState));
            dispatch(storeSelectedDates({}));
            if(updateDashboardResponse?.errorRefresh) {
                dispatch(storeModalMessage({message: "Cambios actualizados correctamente! El Deck pasará a actualizarse. Este cambio tomará unos minutos aproximadamente.", error: true}));
            } else {
                dispatch(storeModalMessage({message: "Dashboard Actualizado", error: false}));
            }
            dispatch(storeModalVisible(true));

            setIsLoading(false);
            
        } catch (error) {
            setIsLoading(false);
            dispatch(storeModalMessage({message: "Hubo un error. Por favor, inténtalo de nuevo.", error: true}));
            dispatch(storeModalVisible(true));

            console.error('Error al guardar datos:', error.message);
        }
    }

    const handleFilterMonthChange = (date) => {
        setFilterMonth(date);
        const monthYearToFilter = date?.format("YYYY-MM");

        if(date) {
            const filteredDataSource = rex_data_deck_listado.filter((item) => {
                const itemMonthYear = moment(item.fecha).format("YYYY-MM");

                return itemMonthYear === monthYearToFilter;
            });

            setDataSource(filteredDataSource);
        } else {
            setDataSource(rex_data_deck_listado);
        }
    }

    return (
        <Row style={{ position: 'relative', placeContent: 'center' }}>
            <div
                style={{
                    position: "absolute",
                    width: "50%",
                    height: "55px",
                    background: "#f3ae61",
                    top: "14px",
                    borderRadius: "15px",
                    paddingLeft: "13px",
                    paddingTop: "3px",
                }}
            >
            <div style={{ fontWeight: "bold" }}>Consideraciones:</div>
            <div style={{ fontSize: "11px" }}>
                    1.- No subir archivos mientras se está replicando o eliminando alguna línea de Listado Deck
            </div>
            <div style={{ fontSize: "11px" }}>
                    2.- El proceso de réplica puede tardar unos 10 minutos por línea y por mes seleccionado
            </div>
            </div>
            <Col span={18} style={{ margin: '25px 0 10px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Listado Deck</div>
			</Col>
            <Col span={6} style={{ margin: '25px 0 10px 0', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <DatePicker 
                    onChange={handleFilterMonthChange} 
                    value={filterMonth} 
                    format="YYYY-MM"
                    placeholder="Select a date"
                    picker="month"
                    disabled={dataSource.some(item => item.sellin_replica_processing || item.delete_decks_processing)}
                />
                <Select
                    style={{ width: '90px'}}
                    onChange={async (e, dt) => {
                        const selected_country = {
                            value: dt.value,
                            label: dt.label,
                        }
                        await dispatch(storeSelectedCountry(selected_country))
                        setFilterMonth(null);
                        dispatch(storeSelectedDates({}));
                        queryClient.invalidateQueries({ queryKey: ['deckListadoervices'] })
                    }}
                    options={rex_data_countries}
                    value={rex_selected_country ? rex_selected_country.label : ''}
                    disabled={dataSource.some(item => item.sellin_replica_processing || item.delete_decks_processing)}
                />
            </Col>
            <Col span={24}>
                <Form>
                    <DndContext 
                        modifiers={[restrictToVerticalAxis]} 
                        onDragEnd={onDragEnd}
                    >
                        <SortableContext 
                            items={rex_data_deck_listado.map((i) => i.mdlorden)} 
                            strategy={verticalListSortingStrategy}
                        >
                            <Table 
                            components={{
                                body: {
                                    row: RowTable,
                                },
                            }}
                            pagination={false}
                            scroll={{
                                x: '1000px',
                                y: 'auto',
                            }}
                            loading={baselineForecast.isPending}
                            columns={columns}
                            dataSource={dataSource} 
                            className="Table-Audits"
                            size='small'
                            />
                        </SortableContext>
                    </DndContext>
                    <Button disabled={dataSource.some(item => item.sellin_replica_processing || item.delete_decks_processing || isLoading)} type="primary" style={{marginTop: '10px'}} onClick={handleSubmitButton} >{isLoading ? 'Actualizando...' : 'Guardar Cambios'}</Button>
                </Form>
                <Modal
                    title="Notificaciones de procesos"
                    open={rex_modal_visible}
                    onCancel={() => {
                        dispatch(storeModalVisible(false));
                    }}
                    footer={null}
                >
                    <Paragraph style={{ fontSize: "16px", textAlign: "center", color: rex_modal_message.error ? "#FFA500" : "#28a745", fontWeight: "bold" }}>
                        {rex_modal_message.message}
                    </Paragraph>
                </Modal>
            </Col>
        </Row>
    )
}

export default DeckListado