import config from "../../config"
import { storeDataCountries, storeDataPermissionsTypeUsers, storeDataPermissionsTypeUsersClone, storeSelectedCountry, storeValidateCountries, storeValidatePermissions } from '../Reducers/Utilities'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataPermissionsServices = async (dispatch) => {
    await fetch(config.api_url+`utilities/get-permissions`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            dispatch(storeDataPermissionsTypeUsers(data.data))

            let data_types_users = data.data
            data_types_users = data_types_users.map(dtu => {
                let pempermisos = dtu.pempermisos
                if(dtu.pempermisos.length > 0){
                    pempermisos = dtu.pempermisos.map(per => ({ ...per, checked: false }))
                }

                return { ...dtu, pempermisos: pempermisos, checked: false }
            })
            dispatch(storeDataPermissionsTypeUsersClone(data_types_users))

            dispatch(storeValidatePermissions(true))

            // notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const GetDataCountriesServices = async (dispatch) => {
    await fetch(config.api_url+`utilities/get-countries`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            let data_countries = data.data
            data_countries = data_countries.map(dc => ({value: dc.paiid, label: dc.painombre}))
            dispatch(storeDataCountries(data_countries))
            if(data.data.length > 0){
                const selected_country = {
                    value: data.data[0]['paiid'],
                    label: data.data[0]['painombre'],
                }

                dispatch(storeSelectedCountry(selected_country))
            }            

            dispatch(storeValidateCountries(true))
            // notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}