import config from "../../config"
import { notification } from 'antd'
import { storeDataDeckListado, storeRangeMonthsSellin } from "../Reducers/DeckListado"
import { refreshDashboardServices } from "./Baseline"
import moment from "moment"

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataDeckListadoServices = async (dispatch, selected_country, rex_data_deck_listado) => {
    try {
        const getDeckListadoResponse = await fetch(config.api_url + `decks/get-deck-listado`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_paiid: selected_country ? selected_country.value : 1,
            })
        });

        const getRangeMonthsSellin = await fetch(config.api_url + `decks/get-months-with-sellin`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                paiid: selected_country ? selected_country.value : 1,
            })
        });


        const data = await getDeckListadoResponse.json();
        const dataRangeMonthsSellin = await getRangeMonthsSellin.json();

        if (data.response) {
            const data_deck_listado = data.data;

            //Revisar si ya hay procesos completados en el estado de redux
            const deckListadoCompleted = rex_data_deck_listado.filter(deck => deck?.completed || deck?.interrupted || deck?.withError || deck?.eliminar || deck?.replicar);
            
            //Comparar paises de los procesos completados con el pais seleccionado
            const deckListadoCompletedCountry = deckListadoCompleted[0]?.paipaises?.paiid;
            const dataDeckListadoCountry = data_deck_listado[0]?.paipaises?.paiid;

            let transformToDataSource;
            if(deckListadoCompleted.length > 0 && deckListadoCompletedCountry === dataDeckListadoCountry){

                transformToDataSource = rex_data_deck_listado;

            } else {

                transformToDataSource = data_deck_listado.map((deckl, index) => ({
                    ...deckl,
                    key: index + 1,
                    eliminar: false, 
                    replicar: false,
                    inQueue: false,
                    completed: false,
                    interrupted: false,
                    withError: false, 
                    meses: [],
                    fecha: moment(deckl.created_at).format('YYYY-MM-DD HH:mm:ss'),
                }));

            }

            dispatch(storeDataDeckListado(transformToDataSource));
            dispatch(storeRangeMonthsSellin(dataRangeMonthsSellin.monthsRange));
        } else {
            notificacionServidor('warning', data.message);
            throw new Error(data.message);
        }

        return true;

    } catch (error) {
        console.log(error);
        throw new Error('Lo sentimos hubo un error en el servidor.');
    }
}

export const ReplicateSellinListadoDeck = async (file) => {

    try {
       const replicateSellinsResponse = await fetch(config.api_url + `file-upload/replicate-sellin`, {
            mode: 'cors',
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
            months: file.meses,
            deckId: file.mdeckid,
            pais: file.paipaises.painombre,
            deckListItemId: file.mdlid
            })
        });
        
        if(replicateSellinsResponse.status !== 200) {
            const errorResponse = await replicateSellinsResponse.json();
            // notificacionServidor('warning', errorResponse.message);
            throw new Error(errorResponse.message);
        }

        return true
    } catch (error) {
        throw new Error (error.message)
    }
}

export const DeleteDeckListadoServices = async (file) => {

    try {
        const deleteDecksResponse = await fetch(config.api_url + `file-upload/replicate-sellin`, {
            mode: 'cors',
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
            deckToDeleteId: file.mdeckid,
            pais: file.paipaises.painombre,
            deckListItemId: file.mdlid
            })
        });

        if(deleteDecksResponse.status !== 200) {
            const errorResponse = await deleteDecksResponse.json();
            // notificacionServidor('warning', errorResponse.message);
            throw new Error(errorResponse.message);
        }
    
        return true
    } catch (error) {
        throw new Error (error.message)
    }
}


export const ChangeStatusDeckListadoServices = async (data, dispatch) => {
    let response = true
    await fetch(config.api_url+`decks/change-deck-listado`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_id: data.mdlid,
            req_state: data.mdlestado,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            // notificacionServidor('success', data.message)
            // dispatch(refreshDashboardServices(dispatch))
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
            response = false
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
        response = false
    })

    return response
}

export const ChangeOrderDeckListadoServices = async (data, dispatch) => {
    let response = true
    await fetch(config.api_url+`decks/change-order-deck-listado`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_order: data,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            // notificacionServidor('success', data.message)
            // dispatch(refreshDashboardServices(dispatch))
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
            response = false
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
        response = false
    })

    return response
}