import config from "../config";
import { filterEmailsByTemplate } from "../helpers/emails/filterEmailsByTemplate";

export const FetchEmails = async () => {
  const emailsData = await fetch(config.api_url + `emails/get-emails`, {
    mode: "cors",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      usutoken: localStorage.getItem("usutoken"),
    },
  });

  const response = await emailsData.json();

  const baselineEmails = filterEmailsByTemplate(response, "baseline");
  const sellinEmails = filterEmailsByTemplate(response, "sellin");
  const selloutEmails = filterEmailsByTemplate(response, "sellout");
  const forecastEmails = filterEmailsByTemplate(response, "forecast");
  const materialesEmails = filterEmailsByTemplate(response, "materiales");
  const clientesEmails = filterEmailsByTemplate(response, "clientes");
  const tipocambioEmails = filterEmailsByTemplate(response, "tipoCambio");
  const dashboard = filterEmailsByTemplate(response, "dashboard");

  return {
    baseline: baselineEmails,
    sellin: sellinEmails,
    sellout: selloutEmails,
    forecast: forecastEmails,
    materiales: materialesEmails,
    clientes: clientesEmails,
    tipocambio: tipocambioEmails,
    dashboard: dashboard,
  };
};
