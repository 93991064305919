import config from "../../../config";

export const FileTypes = async () => {
  const uploadedFilesData = await fetch(
    config.api_url + `file-upload/file-type`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        usutoken: localStorage.getItem("usutoken"),
      },
    }
  );

  const response = await uploadedFilesData.json();

  //   console.log(response);
  return response;
};
