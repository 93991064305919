import React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Table, Input, DatePicker, Select } from "antd";
import { FetchEmails } from "../../Functions/FetchEmails";
import SelectEmails from "../../Components/Emails/SelectEmails";

const EmailControl = () => {
  //States
  const [defaultEmailsTo, setDefaultEmailsTo] = useState({
    baseline: [],
    sellin: [],
    sellout: [],
    forecast: [],
    materiales: [],
    clientes: [],
    tipoCambio: [],
    dashboard: [],
  });
  const [defaultEmailsCc, setDefaultEmailsCc] = useState({
    baseline: [],
    sellin: [],
    sellout: [],
    forecast: [],
    materiales: [],
    clientes: [],
    tipoCambio: [],
    dashboard: [],
  });
  const [defaultEmailsBcc, setDefaultEmailsBcc] = useState({
    baseline: [],
    sellin: [],
    sellout: [],
    forecast: [],
    materiales: [],
    clientes: [],
    tipoCambio: [],
    dashboard: [],
  });

  const templateNames = [
    "baseline",
    "sellin",
    "sellout",
    "forecast",
    "materiales",
    "clientes",
    "tipoCambio",
    "dashboard",
  ];

  //Get emails from database
  useEffect(() => {
    const callFetchEmails = async () => {
      const fetchEmails = await FetchEmails();
      const {
        toEmails: toEmailsBaseline,
        ccEmails: ccEmailsBaseline,
        bccEmails: bccEmailsBaseline,
      } = fetchEmails.baseline;
      const {
        toEmails: toEmailsClientes,
        ccEmails: ccEmailsClientes,
        bccEmails: bccEmailsClientes,
      } = fetchEmails.clientes;
      const {
        toEmails: toEmailsForecast,
        ccEmails: ccEmailsForecast,
        bccEmails: bccEmailsForecast,
      } = fetchEmails.forecast;
      const {
        toEmails: toEmailsMateriales,
        ccEmails: ccEmailsMateriales,
        bccEmails: bccEmailsMateriales,
      } = fetchEmails.materiales;
      const {
        toEmails: toEmailsSellin,
        ccEmails: ccEmailsSellin,
        bccEmails: bccEmailsSellin,
      } = fetchEmails.sellin;
      const {
        toEmails: toEmailsSellout,
        ccEmails: ccEmailsSellout,
        bccEmails: bccEmailsSellout,
      } = fetchEmails.sellout;
      const {
        toEmails: toEmailsTipocambio,
        ccEmails: ccEmailsTipocambio,
        bccEmails: bccEmailsTipocambio,
      } = fetchEmails.tipocambio;

      const {
        toEmails: toEmailsDashboard,
        ccEmails: ccEmailsDashboard,
        bccEmails: bccEmailsDashboard,
      } = fetchEmails.dashboard;

      setDefaultEmailsTo({
        baseline: toEmailsBaseline,
        sellin: toEmailsSellin,
        sellout: toEmailsSellout,
        forecast: toEmailsForecast,
        materiales: toEmailsMateriales,
        clientes: toEmailsClientes,
        tipoCambio: toEmailsTipocambio,
        dashboard: toEmailsDashboard,
      });

      setDefaultEmailsCc({
        baseline: ccEmailsBaseline,
        sellin: ccEmailsSellin,
        sellout: ccEmailsSellout,
        forecast: ccEmailsForecast,
        materiales: ccEmailsMateriales,
        clientes: ccEmailsClientes,
        tipoCambio: ccEmailsTipocambio,
        dashboard: ccEmailsDashboard,
      });

      setDefaultEmailsBcc({
        baseline: bccEmailsBaseline,
        sellin: bccEmailsSellin,
        sellout: bccEmailsSellout,
        forecast: bccEmailsForecast,
        materiales: bccEmailsMateriales,
        clientes: bccEmailsClientes,
        tipoCambio: bccEmailsTipocambio,
        dashboard: bccEmailsDashboard,
      });

      console.log("fetchedEmails: ", fetchEmails);

      return fetchEmails;
    };

    callFetchEmails();
  }, []);

  //create datasource
  const createDataSource = () => {
    return templateNames.map((templateName) => ({
      key: templateName,
      template: templateName,
      to: (
        <SelectEmails
          defaultEmails={defaultEmailsTo}
          setDefaultEmails={setDefaultEmailsTo}
          templateName={templateName}
          receiverType="to"
        />
      ), //here to selector
      cc: (
        <SelectEmails
          defaultEmails={defaultEmailsCc}
          setDefaultEmails={setDefaultEmailsCc}
          templateName={templateName}
          receiverType="cc"
        />
      ), //here cc selector
      bcc: (
        <SelectEmails
          defaultEmails={defaultEmailsBcc}
          setDefaultEmails={setDefaultEmailsBcc}
          templateName={templateName}
          receiverType="bcc"
        />
      ), //here bcc selector
    }));
  };

  const dataSource = createDataSource();

  const columns = [
    {
      title: "template",
      dataIndex: "template",
      width: "15px",
      align: "center",
    },
    {
      title: "to",
      dataIndex: "to",
      width: "50px",
      align: "center",
    },
    {
      title: "cc",
      dataIndex: "cc",
      width: "50px",
      align: "center",
    },
    {
      title: "bcc",
      dataIndex: "bcc",
      width: "50px",
      align: "center",
    },
  ];

  return (
    <>
      <Col span={18} style={{ margin: "20px 0 10px 0" }}>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Control de emails
        </div>
      </Col>
      <Col span={24}>
        <Table
          pagination={false}
          scroll={{
            x: "1000px",
            y: "420px",
          }}
          dataSource={dataSource}
          columns={columns}
          className="Table-Audits Table-Emails"
          size="large"
        />
      </Col>
    </>
  );
};

export default EmailControl;
