import config from "../config";

export const CreateNewEmail = async (email, templateName, recipientType) => {
  const addEmail = await fetch(config.api_url + `emails/add-email`, {
    mode: "cors",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      usutoken: localStorage.getItem("usutoken"),
    },
    body: JSON.stringify({
      eutemail: email,
      eutUploadTemplate: templateName,
      euttype: recipientType,
    }),
  });

  if (!addEmail.ok) {
    throw new Error("Error al agregar el email");
  }

  const response = await addEmail.json();

  return response;
};
