import config from '../../config'
import axios from 'axios'
import { getDataVersionBaselineServices, refreshDashboardServices } from './Baseline'
import { addNotifications, newDataForecast, setStateUploadingFile } from "../Reducers/Fileupload"

//NOTA: Si se llama a dispatch(addNotifications(notificationNew)) dentro de las otras funciones el Mutate no llegara al onSuccess y en lugar de eso se ejecutara el onError, por lo que se debe llamar a dispatch(addNotifications(notificationNew)) dentro de la funcion updateNotifications y no dentro de las funciones Upload*Services.
const updateNotifications = (dispatch, notificationNew) => {
    dispatch(addNotifications(notificationNew))
}

export const UploadPricingMasterServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Precios")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/princing-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch(addNotifications(rex_msgs_notificaciones))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }
        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadRealSellinServices = async (dispatch, rex_msgs_notificaciones, rex_data_user, fileList, titleFile = "Real Sell In")  => {

    const userId = rex_data_user?.usuid ? rex_data_user.usuid : null;

    setStateUploadingFile(true)

    const formData = new FormData()

    let updateDashboard = false
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/transactional-sellin', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'usuid'        : userId ? userId : null,
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){
                updateDashboard = true
                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }

                updateNotifications(dispatch, notificationNew);
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }
        updateNotifications(dispatch, notificationNew);

        throw new Error(error.response.data.message);

    }finally{
        setStateUploadingFile(false)
        if(updateDashboard){
           await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadMasterExChangeRateServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "MD tipo de cambio")  => {

    setStateUploadingFile(true)

    const formData = new FormData()
    let updateDashboard = false
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/master-exchange-rate', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                updateDashboard = true
                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }
                updateNotifications(dispatch, notificationNew);
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }
        updateNotifications(dispatch, notificationNew);

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)
        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadMasterClientsServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Master Clients")  => {

    setStateUploadingFile(true)

    const formData = new FormData()
    let updateDashboard = false
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/client-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                updateDashboard = true

                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : [],
                    "isOpen"    : false
                }

                updateNotifications(dispatch, notificationNew);
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }
        updateNotifications(dispatch, notificationNew);

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)
        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadBaselineServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Baseline", version, rex_data_user)  => {

    const userId = rex_data_user?.usuid ? rex_data_user.usuid : null;

    const formData = new FormData()

    let updateDashboard = false

    setStateUploadingFile(true)
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })

    formData.append('name', version.name)

    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/forecast', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'usuid'        : userId ? userId : null,
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }
                updateNotifications(dispatch, notificationNew)

                updateDashboard = true
                getDataVersionBaselineServices(dispatch)
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }
        updateNotifications(dispatch, notificationNew)

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)
        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadSellOutServices = async (dispatch, rex_msgs_notificaciones, fileList, rex_data_user, titleFile = "Sell Out")  => {

    const userId = rex_data_user?.usuid ? rex_data_user.usuid : null;

    setStateUploadingFile(true)

    const formData = new FormData()
    let updateDashboard = false

    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/sell-out', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'usuid'        : userId ? userId : null,
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                updateDashboard = true
                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }
                updateNotifications(dispatch, notificationNew);
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }

        updateNotifications(dispatch, notificationNew);

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)

        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const CargaMasterSellIn = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Sell In")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/sell-in', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch(addNotifications(rex_msgs_notificaciones))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadProductMasterServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Productos")  => {

    setStateUploadingFile(true)

    const formData = new FormData()
    let updateDashboard = false
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/product-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                updateDashboard = true

                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : [],
                    "isOpen"    : false
                }

                updateNotifications(dispatch, notificationNew)
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }

        updateNotifications(dispatch, notificationNew)

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)
        
        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadForecastServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Forecast", value_forecast, rex_data_user)  => {

    const userId = rex_data_user?.usuid ? rex_data_user.usuid : null;
    
    setStateUploadingFile(true)

    const formData = new FormData()
    let updateDashboard = false

    fileList.forEach((file) => {
        formData.append('data_file', file)
    })

    formData.append('req_forecast', JSON.stringify(value_forecast))
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/forecast-versions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'usuid'        : userId ? userId : null,
            },
        })

        if(rquest.status){
            if(rquest.status == 200){

                updateDashboard = true

                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }

                updateNotifications(dispatch, notificationNew)
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : error.response.data.message,
            "respuesta" : false,
            "data"      : error.response.data.notificaciones,
            "isOpen"    : false
        }

        updateNotifications(dispatch, notificationNew)

        throw new Error(error.response.data.message);
    }finally{
        setStateUploadingFile(false)
        GetDataForecastServices(dispatch)
        
        if(updateDashboard){
            await refreshDashboardServices(dispatch)
        }
    }

    return rpta
}

export const UploadProductMasterLastServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Productos Last")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/product-master-last', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){
                let newNotify = {
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                }
                dispatch(addNotifications(newNotify))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const GetDataForecastServices = async (dispatch)  => {
    await fetch(config.api_url+'baseline/get-data-forecast',
		{
			mode:'cors',
			method: 'POST',
			headers: {
                'Accept' : 'application/json',
				'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
			},
            body: JSON.stringify({}),
      	}
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            let data_forecast = data.data
            data_forecast = data_forecast.map(df => ({ label: df.mdeckname, value: df.mdeckid }))
            dispatch(newDataForecast(data_forecast))
        }
    })
    .catch((error) => {
        console.log("Forecast error: ", error)
    })

    return true
}

export const DownloadTemplatesServices = async (name)  => {
    await fetch(config.api_url+'file-upload/download-templates',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                file_name: name
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const link = document.createElement('a')
            link.href = data.data.url
            link.download = data.data.file_name
            link.click()
        }
    })
    .catch((error)=> {
        console.log(error)
    })
    
}