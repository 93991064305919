import { Dropdown } from 'antd'
import {
	DownOutlined,
} from '@ant-design/icons'
import { LogOutReducer } from '../../Redux/Services/Header'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const Logout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const items = [
        {key: 0, label: <div onClick={async () => {
            await LogOutReducer(dispatch)
            navigate('/deck')
        }}>Cerrar Sesión</div>},
    ]

    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
            className="Menu-Log-Out"
        >
            <div>
                <DownOutlined />
            </div>
        </Dropdown>
    )
}

export default Logout