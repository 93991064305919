import config from "../../config"
import { notification } from 'antd'
import { storeDataClientsPending, storeDataProductsPending } from "../Reducers/ClientsProductsPending"

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataDeckListadoServices = async (dispatch, selected_country) => {
    await fetch(config.api_url+`products-clients-pending/get-products-clients-pending`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_type: selected_country.value,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const { data_cliente, data_producto } = data.data
            
            dispatch(storeDataClientsPending(data_cliente))
            dispatch(storeDataProductsPending(data_producto))
            // notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}
