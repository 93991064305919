// const api_url = 'https://dev-back-hml-thanos.grow-solutions.com/'
const api_url = 'https://back-syop.grow-corporate.com/'
// const api_url = 'http://127.0.0.1:8010/'
export default {
    api_url_socket : "",
    // api_url_socket : api_url,
    api_url : api_url + "protected/",
    api_url_notprotected : api_url,
    basename: '/',
    produccion : false,
}