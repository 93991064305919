import { Select } from "antd";
import { DeleteEmail } from "../../Functions/DeleteEmail";
import { CreateNewEmail } from "../../Functions/CreateNewEmail";
import "../../Styles/Components/Emails/EmailsByTemplate.css";

const SelectEmails = ({
  defaultEmails, //defaultEmails
  setDefaultEmails, //setDefaultEmails
  templateName,
  receiverType,
}) => {
  return (
    <Select
      mode="multiple"
      maxTagCount={3}
      size="small"
      className="emailSelector"
      placeholder={`Select email ${receiverType}: `}
      value={defaultEmails[templateName]}
      onInputKeyDown={async (e) => {
        if (e.key === "Enter" && e.target.value) {
          //New email
          const value = e.target.value;
          if (!defaultEmails[templateName].includes(value)) {
            try {
              //Add Email
              const emailAdded = await CreateNewEmail(
                value,
                templateName, //needs to be dynamic
                receiverType
              );
              // alert(emailAdded.msg);

              setDefaultEmails((prev) => ({
                ...prev,
                [templateName]: [...(prev[templateName] || []), value],
              }));
            } catch (error) {
              console.error("Mensaje:", error);
              // alert("Error al agregar email");
            }
          }
        }
      }}
      onChange={async (newValue) => {
        //Get the deleted Email
        const deletedEmail = defaultEmails[templateName].filter(
          (email) => !newValue.includes(email)
        );

        //Delete email on database
        if (deletedEmail.length > 0) {
          try {
            await DeleteEmail(deletedEmail[0], templateName, receiverType);
            // alert(`Email eliminado exitosamente: ${deletedEmail[0]}`);
            console.log(`Email eliminado: ${deletedEmail[0]}`);
          } catch (error) {
            console.error("Error eliminando email:", error);
            // alert("Error al eliminar email");

            return; // Exit the function if deletion fails
          }
        }

        //Update the default emails
        setDefaultEmails((prev) => ({
          ...prev,
          [templateName]: newValue,
        }));
      }}
    >
      {defaultEmails[templateName].map((email, index) => {
        return (
          <Select.Option key={index} value={email}>
            {email}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SelectEmails;
