import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetUsersServices, DeleteUsersServices } from "../../../Redux/Services/Administrator/Users"
import { Row, Col, Popconfirm, Table, Input, Button } from 'antd'
import {
    FilterOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import '../../../Styles/Routes/Administrator/TypeUsers/TypeUsers.css'

const Users = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 15
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const getusers = useQuery({
        queryKey: ['getusers'],
        queryFn : () => GetUsersServices(dispatch),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const deleteUser = useMutation({
        mutationFn: (type_user_id) => DeleteUsersServices(type_user_id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getusers'] })
        }
    })

    const { 
        rex_data_users_clone, 
    } = useSelector(state => state.users)

    const getColumnSearchProps = (dataIndex, texto) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{padding: 8,}}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${texto}`}
                    value={selectedKeys[0]}
                    onChange={async (e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{display: 'block'}}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color: '#fff'}} />),
        onFilter: (value, record) => {
            let valueData
            if(dataIndex.length == 3){
                if(record[dataIndex[0]]){
                    if(record[dataIndex[0]][dataIndex[1]]){
                        valueData = record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
                    }
                }
            }else if(dataIndex.length == 2){
                if(record[dataIndex[0]]){
                    valueData = record[dataIndex[0]][dataIndex[1]]
                }
            }else if(dataIndex.length == 1){
                valueData = record[dataIndex[0]]
            }

            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columns = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, __, index) => <div title={index + 1 + (currentPage - 1) * pageSize}>{index + 1 + (currentPage - 1) * pageSize}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Estado',
            dataIndex: 'usuestado',
            sorter: (a, b) => a.usuestado ? a.usuestado - b.usuestado : false,
            showSorterTooltip: false,
            render: (usuestado) => <div style={{display:'flex', justifyContent:'center'}}>
                <div title={usuestado ? 'Activado' : 'Desactivado'} className={usuestado ? 'Status-Color-Green' : 'Status-Color-Red'}></div>
            </div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Tipo de usuario',
            dataIndex: 'tpunombre',
            ...getColumnSearchProps(['tputiposusuarios', 'tpunombre'], 'Tipo de usuario'),
            sorter: (a, b) => {
                const columnA = a?.tputiposusuarios?.tpunombre || ''
                const columnB = b?.tputiposusuarios?.tpunombre || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            showSorterTooltip: false,
            render: (_, record) => <div title={record.tputiposusuarios ? record.tputiposusuarios?.tpunombre : '-'}>{record.tputiposusuarios ? record.tputiposusuarios?.tpunombre : '-'}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            ...getColumnSearchProps(['usuusuario'], 'Usuario'),
            sorter: (a, b) => {
                const columnA = a?.usuusuario || ''
                const columnB = b?.usuusuario || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            showSorterTooltip: false,
            render: (_, record) => <div title={record?.usuusuario}>{record?.usuusuario}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Nombres y apellidos',
            dataIndex: 'perpersonas',
            sorter: (a, b) => a.perpersonas ? a.perpersonas.pernombrecompleto.localeCompare(b.perpersonas.pernombrecompleto) : false,
            ...getColumnSearchProps(['perpersonas', 'pernombrecompleto'], 'Nombre y apellido'),
            sorter: (a, b) => {
                const columnA = a?.perpersonas?.pernombrecompleto || ''
                const columnB = b?.perpersonas?.pernombrecompleto || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            showSorterTooltip: false,
            render: (_, record) => <div title={record.perpersonas ? record.perpersonas?.pernombrecompleto : '-'}>{record.perpersonas ? record.perpersonas?.pernombrecompleto : '-'}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => {
                        navigate(`/administrador/editar-usuarios/${record.usuid}`)
                    }}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <Popconfirm
                    title='Eliminar usuario'
                    description='Estás seguro de eliminar el usuario?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => {
                        deleteUser.mutate(record.usuid)
                    }}
                >
                    <DeleteOutlined 
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>
            </div>,
            width: '50px',
            align:'center'
        }
    ]

	return (
		<Row>
			<Col span={24} style={{ margin: '25px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Usuarios</div>
			</Col>
            <Col span={24}>
                <Button
                    size="small"
                    type="primary"
                    className="Button-New"
                    onClick={() => navigate('/administrador/crear-usuarios')}
                >
                    Nuevo Usuario <PlusOutlined />
                </Button>
            </Col>
            <Col span={24}>
                <Table 
                    pagination={{
                        position: ["topRight", "none"],
                        pageSize: pageSize,
                        size: 'small',
                        onChange: (page) => setCurrentPage(page),
                        showTotal: (total) => `Total: ${total} registros`
                    }}
                    scroll={{
                        x: '1000px',
                        y: '370px',
                    }}
                    loading={getusers.isPending}
                    columns={columns}
                    dataSource={rex_data_users_clone} 
                    className="Table-Audits"
                    size='small'
                />
            </Col>
		</Row>
	)
}

export default Users